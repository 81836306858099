import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { Download } from './download';
import Logo from './Logo';
import { Register } from './register';
function App() {
  return (
    <div className="App">
      <Logo />
      <div style={{textAlign: 'center'}}>FUGUO</div>
      <Routes>
        <Route path='/' element={<Navigate to='/register' />}/>
        <Route path='/register' element={<Register />}/>
        <Route path='/download' element={<Download />}/>
      </Routes>
    </div>
  );
}

export default App;
