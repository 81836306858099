import React, { useCallback, useEffect, useState } from 'react'
const BaseURL = process.env.REACT_APP_BaseURL
export function Download() {
    // const [ios, setIos] = useState({
    //     version: {}
    // })
    const [android, setAndroid] = useState({
        version: {}
    })
    const [isWx, setIsWx] = useState(false)
    // const getIos = useCallback(() => window.axios(BaseURL + 'upgrade', { params: { platform: 'ios', version: '1.0.1' } }).then(res => res.data), [])
    const getAndroid = useCallback(() => window.axios(BaseURL + 'upgrade', { params: { platform: 'android', version: '1.0.1' } }).then(res => res.data), [])

    useEffect(() => {
        const p = Promise.all([getAndroid()])
        p.then(([randroid]) => {
            setAndroid(randroid.data)
            // setIos(rios.data)
        })
    }, [getAndroid])

    useEffect(() => {
        // 判断浏览器
        function isWeiXin() { var a = navigator.userAgent.toLowerCase(); const match = a.match(/MicroMessenger/i); if (match && match[0] === "micromessenger") { return true; } else { return false; } }
        setIsWx(isWeiXin())
    }, [])
    return <div className='download-page'>
        <img className='download-img' src={require('./downloadimg.png')} alt='' />
        <div className='download-text'>下载App</div>
        <div className=''>
            {/* <a href={ios.version.download_page} className='button ios' target='_blank' rel="noreferrer" ><img className='icon ios-icon' src={require('./ios.png')} alt='' /> 苹果应用下载</a> */}
            <a href={android.version.download_page} className='button android' target='_blank' rel="noreferrer"><img className='icon android-icon' src={require('./android.png')} alt='' /> 安卓应用下载</a>
        </div>
        {
            isWx &&
            <div className='cover'>
                <div className='download-tip'>
                    点击右上角
                    <svg t="1649906076324" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10340" width="128" height="128"><path d="M150.528 431.104q37.888 0 58.368 24.064t20.48 51.712l0 11.264q0 34.816-17.92 58.88t-59.904 24.064l-7.168 0q-38.912 0-61.952-21.504t-23.04-59.392l0-14.336q0-13.312 5.632-26.624t15.872-24.064 25.6-17.408 33.792-6.656l10.24 0zM519.168 431.104q37.888 0 58.368 24.064t20.48 51.712l0 11.264q0 34.816-17.92 58.88t-59.904 24.064l-7.168 0q-38.912 0-61.952-21.504t-23.04-59.392l0-14.336q0-13.312 5.632-26.624t15.872-24.064 25.6-17.408 33.792-6.656l10.24 0zM887.808 431.104q37.888 0 58.368 24.064t20.48 51.712l0 11.264q0 34.816-17.92 58.88t-59.904 24.064l-7.168 0q-38.912 0-61.952-21.504t-23.04-59.392l0-14.336q0-13.312 5.632-26.624t15.872-24.064 25.6-17.408 33.792-6.656l10.24 0z" p-id="10341" fill="#ffffff"></path></svg>
                    在浏览器打开
                    <svg t="1649906446174" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="21955" width="128" height="128"><path d="M744.825301 568.816439c-124.509932 124.493676-249.001803 249.001803-373.509929 373.49548-13.996395 13.996395-36.684317 13.996395-50.678905 0L81.688081 703.363533c-13.996395-13.996395-13.996395-36.684317 0-50.678905 124.50632-124.493676 249.001803-249.001803 373.49548-373.495479-27.051749-27.066199-54.116141-54.119754-81.184147-81.185953-21.403701-21.416344-8.252617-58.086212 21.880543-61.008675 166.853135-16.190951 333.704464-32.371065 500.5576-48.562016 22.449502-2.16927 41.313658 16.682243 39.129938 39.129938-16.178308 166.853135-32.369259 333.704464-48.547567 500.5576-2.924269 30.134966-39.60678 43.296887-61.02493 21.880543-27.053555-27.066199-54.117948-54.117948-81.169697-81.184147z m74.118216-34.473504l35.373002-364.659454-364.645004 35.373002 74.132665 74.132666L164.960217 678.033112l181.021121 181.019314 398.842157-398.842157 74.120022 74.132666z" fill="#ffffff" p-id="21956" ></path></svg>
                </div>
            </div>
        }

    </div>
}