import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import FormBox from './FormBox';
// import SendCode from './SendCode';
const BaseURL = process.env.REACT_APP_BaseURL
export function getQueryParam(windowLocation, name) {
    var q = windowLocation.search.match(new RegExp('[?&]' + name + '=([^&#?]*)'))
    return q && q[1]
}



export function Register() {
    const navigate = useNavigate()
    const [formData, setFormdata] = useState({
        mobile: '',
        code: '',
        password: '',
        repassword: '',
        invite_code: ''
    })

    const [submitting, setSubmitting] = useState(false)

    const [error, setError] = useState('')
    const onSubmit = useCallback(async (evt) => {
        evt.preventDefault()
        setSubmitting(true)
        try {
            await window.axios({
                method: 'post',
                url: BaseURL + 'register',
                data: {
                    mobile: formData.mobile,
                    // sms_code: formData.code,
                    password: formData.password,
                    invite_code: formData.invite_code
                },
                headers: {
                    // 'Access-Control-Allow-Origin': 'http://localhost:3000/'
                }
            })
            navigate('/download')
        } catch (error) {
            if (error && error.response) {
                setError(error.response.data.message)
            }
        }
        setSubmitting(false)
    }, [formData, navigate])
    const onChange = useCallback((evt) => {
        setFormdata(prevformData => ({ ...prevformData, [evt.target.name]: evt.target.value }))
    }, [setFormdata])
    const send = useCallback(async () => {
        if (!formData.mobile) throw Error('请填写手机号')
        try {
            
            await window.axios({ method: 'get', url: BaseURL + 'sms', params: { mobile: formData.mobile } })
        } catch (error) {
            if (error && error.response) {
                setError(error.response.data.message)
            }
            throw error
        }
        // throw Error('message')
    }, [formData])
    useEffect(() => {
        const invite_code = getQueryParam(window.location, 'invite_code') || ''
        setFormdata(prevformData => ({ ...prevformData, invite_code }))
    }, []);
    return (
        <form onSubmit={onSubmit}>
            <FormBox value={formData.mobile} name='mobile' onChange={onChange} label='手机号' placeholder='请输入手机号' />
            {/* <FormBox value={formData.code} name='code' onChange={onChange} label='验证码' placeholder='请输入验证码' extra={<SendCode send={send} />} /> */}
            <FormBox value={formData.password} type='password' name='password' onChange={onChange} label='设置密码' placeholder='请设置密码' />
            <FormBox value={formData.repassword} type='password' name='repassword' onChange={onChange} label='确认密码' placeholder='请再次输入密码' />
            <FormBox value={formData.invite_code} name='invite_code' onChange={onChange} label='邀请码' placeholder='请输入邀请码' />
            <div className='error'>
                {error}
            </div>
            <button className='primary' disabled={submitting} type='submit'>确定</button>
            <button className='primary' type='button' onClick={() => navigate('/download')}>已有账号？去下载</button>
            <div style={{ padding: 15 }}></div>
        </form>
    );
}
